<script setup>
import InputError from '@/Components/InputError.vue';
import InputLabel from '@/Components/InputLabel.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';
import TextInput from '@/Components/TextInput.vue';
import { loadLanguageAsync, getActiveLanguage } from 'laravel-vue-i18n';
import { onBeforeMount, ref } from 'vue';
import { Link, useForm, usePage } from '@inertiajs/vue3';
import ClasoraSVG from '../../images/clasora.svg?component';
import GridGraySVG from '../../images/grid-gray.svg?component';
import GridGreenSVG from '../../images/grid-green.svg?component';
import MessageGraySVG from '../../images/message-gray.svg?component';
import MessageGreenSVG from '../../images/message-green.svg?component';
import MenuGraySVG from '../../images/menu-gray.svg?component';
import MenuGreenSVG from '../../images/menu-green.svg?component';
import ProfileGraySVG from '../../images/profile-gray-icon.svg?component';
import ProfileGreenSVG from '../../images/profile-green-icon.svg?component';

const currentLanguage = ref(getActiveLanguage());
const user = usePage().props.auth.user;

</script>

<template>
    <div class="fixed bottom-0 left-0 right-0 bg-white shadow-card block md:hidden z-[100]">
        <nav class="flex justify-around pb-4">   
            <a v-if="route().current() === `posts.${currentLanguage}`"
              class="flex-1 p-3.5 text-center border-t border-primary"
              :href="route(`posts.${currentLanguage}`)"
              
              >
                <ClasoraSVG class="mx-auto" />
                <span class="text-primary text-xs">Clasora</span>
            </a>
            <a v-else 
                class="flex-1 p-3.5 text-center border-t" :href="route(`posts.${currentLanguage}`)">
                <ClasoraSVG class="mx-auto" />
                <span class="text-gray-500 text-xs">Clasora</span>
            </a>
            <a v-if="user && route().current() === `dashboard.${currentLanguage}`"
              class="flex-1 p-3.5 text-center border-t border-primary"
              :href="route(`dashboard.${currentLanguage}`)">
                <GridGreenSVG class="mx-auto" />
                <span class="text-primary text-xs">{{ $t('Dashboard') }}</span>
                
            </a>
            <a v-else-if="user" 
                class="flex-1 p-3.5 text-center border-t" :href="route(`dashboard.${currentLanguage}`)">
                <GridGraySVG class="mx-auto" />
                <span class="text-gray-500 text-xs">{{ $t('Dashboard') }}</span>
            </a>
            <a v-if="user && route().current() === `messages.${currentLanguage}`"
              class="flex-1 p-3.5 text-center border-t border-primary"
              :href="route(`messages.${currentLanguage}`)">
                <MessageGreenSVG class="mx-auto" />
                <span class="text-primary text-xs"
                >{{ $t('Messages') }}</span>
            </a>
            <a v-else-if="user"
                class="flex-1 p-3.5 text-center border-t" :href="route(`messages.${currentLanguage}`)">
                <MessageGraySVG class="mx-auto" />
                <span class="text-gray-500 text-xs">{{ $t('Messages') }}</span>
            </a>
            <a 
              v-if="!user && route().current() === `login.${currentLanguage}`"
              class="flex-1 p-3.5 text-center border-t border-primary cursor-pointer"
            >
                <ProfileGreenSVG class="mx-auto" />
                <span class="text-primary text-xs"
                >{{ $t('Profile') }}</span>
            </a>
            <a v-else-if="!user"
                :href="route(`login.${currentLanguage}`)"
                class="flex-1 p-3.5 text-center border-t">
                <ProfileGraySVG class="mx-auto" />
                <span class="text-gray-500 text-xs">{{ $t('Profile') }}</span>
            </a>
            <a 
              v-if="route().current() === `menu.${currentLanguage}`"
              class="flex-1 p-3.5 text-center border-t border-primary cursor-pointer"
            >
                <MenuGreenSVG class="mx-auto" />
                <span class="text-primary text-xs"
                >{{ $t('Menu') }}</span>
            </a>
            <a v-else
                :href="route(`menu.${currentLanguage}`)"
                class="flex-1 p-3.5 text-center border-t">
                <MenuGraySVG class="mx-auto" />
                <span class="text-gray-500 text-xs">{{ $t('Menu') }}</span>
            </a>
        </nav>
    </div>
</template>

